<template>
  <div>
    <base-page
      title="Jurisdictions"
      :breadcrumbs="breadcrumbs"
      call-to-action-text="Add jurisdiction"
      call-to-action-permission="nchi.jurisdictions.store"
      @call-to-action="dialog = true"
    >
      <template #content>
        <jurisdiction-list
          @edit="edit"
        />
      </template>
    </base-page>

    <jurisdiction-form
      v-if="dialog"
      :dialog="dialog"
      :jurisdiction="jurisdiction"
      @close="close()"
    ></jurisdiction-form>
  </div>
</template>

<script>
export default {
  components: {
    'jurisdiction-list': () => import('@/views/nchi/JurisdictionList.vue'),
    'jurisdiction-form': () => import('@/views/nchi/JurisdictionForm.vue'),
  },

  data () {
    return {
      dialog: false,
      jurisdiction: null,
      breadcrumbs: [
        { text: 'Jurisdictions', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
    },

    edit (jurisdiction) {
      this.jurisdiction = jurisdiction
      this.dialog = true
    }
  }
} 
</script>